<template>
    <!-- HOME -->
    <section>
        <div class="container-alt">
            <div class="row">
                <div class="col-sm-12">

                    <div class="wrapper-page">

                        <div class="m-t-40 account-pages bg-white">
                            <div class="text-center account-logo-box bg-white">
                                
                            </div>
                            <div class="account-content">
                                <h2 class="text-uppercase font-bold m-b-0 text-center mb-5">BANK DATA GIZKIA</h2>
                                <form class="form-horizontal">

                                    <div class="form-group row d-flex justify-content-center mb-3 m-t-40">
                                        <div class="col-lg-10 col-sm-12">
                                            <input class="form-control" type="text" v-model="username" required="" placeholder="Username">
                                        </div>
                                    </div>

                                    <div class="form-group row d-flex justify-content-center mb-3">
                                        <div class="col-lg-10 col-sm-12">
                                            <input class="form-control" v-model="password" type="password" required=""
                                                placeholder="Password">
                                        </div>
                                    </div>
                    
                                    <div class="form-group account-btn d-flex justify-content-center m-t-10">
                                        <div class="col-9 d-flex justify-content-center">
                                            <button @click="signIn()" class="btn w-md btn-custom waves-effect waves-light btn-block"
                                                type="button">Log In</button>
                                        </div>
                                    </div>

                                     <div class="form-group text-center m-t-60">
                                        <div class="col-sm-12">
                                            <a href="#" class="text-muted"><i
                                                    class="fa fa-lock m-r-5"></i> Lupa Password?</a>
                                        </div>
                                    </div>
                                </form>

                                <div class="clearfix"></div>

                            </div>
                        </div>
                        <!-- end card-box-->

                    </div>
                    <!-- end wrapper -->

                </div>
            </div>
        </div>
    </section>
    <!-- END HOME -->
</template>

<script>
export default {
    name: 'Login'
}
</script>

<style scoped>
.wrapper-page {
    max-width: 439px;
}

.account-pages {
    border-right:solid 8px #C026D3;
    height: 500px;
}

.account-pages .account-content {
    padding-top: 84px;
}

.m-t-60 {
    margin-top: 90px;
}
</style>